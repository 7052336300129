body {
  /* font-family: Arial, Helvetica, sans-serif; */
  margin: 0;
  padding: 0;
  height: 100%;
}

.container {
  font-size: 3rem;
  margin: auto;
  max-width: 800px;
  margin-top: 20px;
}
